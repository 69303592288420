import { PlushcareWebAPI, apiUrl } from 'src/js/utils';
import {
  post, get, success, V2Response, isError, error as v2Error,
} from 'src/js/utils/apiV2';
import { useUserData } from 'src/js/endpoints/users';
import { UserSubscription } from 'src/js/reducers/UserReducer';
import useSWR from 'swr';
import { useSSRSWR } from 'src/js/endpoints/ServerSideSWR';
import { AsyncCostEstimateLookupsType } from 'src/js/components/bookingFlow/insurance/InsuranceLookup/helpers';

export interface LookupResponse {
  coinsurance_amount?: string,
  coinsurance_percent?: string,
  copay_amount: string,
  coverage_id: number,
  coverage_type: CoverageType,
  credits?: string,
  deductible_amount?: string,
  deductible_waived: boolean,
  in_network: boolean,
  price: string,
  reason: string,
  same_day_discount: boolean,
  user_insurance: boolean,
  name: string,
  lock_credentials: boolean,
  profile_override: any,
  member_id: string,
  explanation: string,
  is_medicare?: boolean,
  is_insured: boolean,
  out_of_network: boolean,
}

export interface AsyncLookupResponse {
  appointment_uuid: string,
}

export interface LookupPostData {
  first_name: string,
  last_name: string,
  member_id?: string,
  date_of_birth: string,
  payer_id: string,
  payer_name: string,
  doctor_id?: number,
  dependent_first_name?: string,
  dependent_last_name?: string,
  dependent_dob?: string,
  member_first_name?: string,
  member_last_name?: string,
  member_dob?: string,
  coverage_uuid?: string,
  appointment_uuid?: string,
}

export type AsyncLookupPostData = Partial<{
  cost_estimate_request: LookupPostData,
  appointment_uuid: string,
  estimated_price: number,
}> | undefined

type CancelAsyncLookupPostData = {
  cost_lookup_uuid: string,
}

export interface PayerDetailsResponse {
  name: string,
  display_name: string,
  payer_id: string,
  smart_verify: boolean,
}

export interface AttachLookUpErrorData {
  estimated_price: number,
  code: string,
  user_message: string,
}

export const postCostEstimate = (postData: LookupPostData): Promise<V2Response<LookupResponse>> => PlushcareWebAPI.apiPost<LookupPostData>('/v2/insurance/cost-estimate/', postData, true);

export const postAsyncCostEstimate = (payload?: AsyncLookupPostData) => post<AsyncLookupPostData, AsyncLookupResponse>('/v2/insurance/async-cost-estimate/', payload);

export const postAttachCostEstimate = (payload: LookupPostData) => post<LookupPostData, LookupResponse, AttachLookUpErrorData>('/v2/insurance/attach-cost-estimate/', payload);

export const cancelAsyncCostEstimate = (payload: CancelAsyncLookupPostData) => post<CancelAsyncLookupPostData, AsyncLookupResponse>('/v2/insurance/cancel-async-lookup/', payload);

export const getPayerDetails = (payerName: PayerDetailsResponse) => get<Array<PayerDetailsResponse>, any>(`/v2/insurance/payer-names/?name=${payerName}`);

export const searchPayerNames = (payerName: string) => get<Array<PayerDetailsResponse>, any>(`/v2/insurance/payer-names/?name=${payerName}`);

// TODO:: this should come off same endpoint as insurance/payer-names on BE
export type PartialPayer = Pick<PayerDetailsResponse, 'display_name' | 'name' | 'payer_id'>;
export const getTopPayers = async (): Promise<V2Response<PartialPayer[]>> => {
  const { data, error } = await PlushcareWebAPI.staticServerGet({
    // baseURLOverride: 'https://api.plushcare.com/', - Uncomment this line when medicare is flag is removed
    url: apiUrl.getTopPayers,
  });

  if (error) {
    return v2Error({
      code: 'CUSTOM',
      code_alt: 'CS',
      message: error.message || '[Error]: Failed fetching top payers',
      error_data: error.data || null,
    });
  }

  // mapping to wanted object from pairs
  return success(data.map(([payer_id, name]: [string, string]) => ({ payer_id, name, display_name: name })));
};

export type CostEstimateResponse = {
  applied_bonus: string | null,
  price: string
}

export const getDefaultUninsuredPrice = (): Promise<CostEstimateResponse> => (
  PlushcareWebAPI.post(apiUrl.pricing.primaryCare.getDefaultUninsuredPrice, {}, false, true).then(
    ({ data }) => {
      return data;
    },
  ));

export const COVERAGE_TYPES = {
  ENTERPRISE: 'enterprise',
  ELIGIBLE: 'eligible',
  TPA: 'tpa',
  APERO: 'apero',
} as const;

export type CoverageType = typeof COVERAGE_TYPES[keyof typeof COVERAGE_TYPES];

export function isInsuredCoverage(coverage: CoverageResponse): boolean {
  if (coverage.is_insured) {
    return true;
  }
  // @ts-expect-error TypeScript complains that `NotInsuredCoverage` doesn't have the `coverage_type` property
  const coverageType = coverage?.coverage_type || null;
  if (!coverageType) {
    return false;
  }
  // as of 2024-09-20 supplemental coverages do not have the `is_insured` field, so this is a temporary workaround
  // to hit the Medicare launch date
  return Object.values(COVERAGE_TYPES).includes(coverageType);
}

type Coverage = {
  first_name: string,
  last_name: string,
  date: string,
  patient_first_name: string,
  patient_last_name: string,
  patient_dob: string,
  patient_is_dependant: boolean,
  other?: Omit<InsuredEligibleCoverage[] | InsuredCoverage[] | InsuredDependentCoverage[] | InsuredEligibleCoverage[], 'other' | 'is_insured'>,
  async_cost_lookups?: AsyncCostEstimateLookupsType[],
}

type NotInsuredCoverage = Coverage & {
  is_insured: false,
  coverage_type: CoverageType,
  is_medicare: boolean,
}
// self coverage; coverage type isn't eligible; payer_id not set
type InsuredCoverage = Coverage & {
  price: string,
  coverage_type: CoverageType,
  payer_name: string,
  member_id: string,
  is_insured: true,
  is_medicare: boolean,
  coverage_uuid: string,
}
// dependent coverage; coverage type isn't eligible; payer_id not set
type InsuredDependentCoverage = Omit<InsuredCoverage, 'patient_is_dependant'> & {
  patient_is_dependant: true,
  primary_first_name: string,
  primary_last_name: string,
  primary_dob: string
}
// self coverage; coverage type is eligible; payer_id set
export type InsuredEligibleCoverage = InsuredCoverage & {
  payer_id: string,
  primary_first_name: string,
  primary_last_name: string,
  primary_dob: string,
}
// dependent coverage; coverage type is eligible; payer_id set
type InsuredDependentEligibleCoverage = InsuredDependentCoverage & {
  payer_id: string,
}

export type CoverageResponse =
  | NotInsuredCoverage
  | InsuredCoverage
  | InsuredDependentCoverage
  | InsuredEligibleCoverage
  | InsuredDependentEligibleCoverage

export type InsuredCoverageResponse = Exclude<CoverageResponse, NotInsuredCoverage>

export const getCoverage = async (): Promise<CoverageResponse> => {
  const resp = await get<CoverageResponse>(apiUrl.patients.getCoverage);

  if (isError(resp)) {
    // why we do it?
    // because there is general issue between using useSWR with v2 helpers
    // we need to make version of v2 helpers which will just be compatible with useSWR
    throw resp;
  }
  return resp.payload as CoverageResponse;
};

// if no `coverage_uuid` is passed this will remove the user's primary insurance, which will also remove any
// supplemental coverages
// if a `coverage_uuid` from supplemental coverage is passed, that single supplemental coverage will be removed
export const removeInsurance = (coverage_uuid?: string | null) => post('/v2/insurance/remove/', { coverage_uuid });

export const useCoverage = (forceRevalidate = false) => {
  const { data: userData } = useUserData();

  // Prevent fetching coverage if user is not logged in
  const cacheKey = userData.is_logged_in ? 'profile_coverage' : null;

  const {
    data,
    error,
    isValidating,
    mutate,
  } = useSSRSWR(cacheKey, getCoverage, {}, forceRevalidate);

  return {
    data,
    error,
    isValidating,
    isLoading: isValidating && !data,
    mutate,
  };
};

export const useIsTPAUser = () => {
  const { data: coverage } = useCoverage();
  return (coverage?.is_insured && coverage.coverage_type === 'tpa') || false;
};

export const useValidUserSubscriptions = (): UserSubscription[] | null => {
  const { data: user } = useUserData();
  const subscriptions = user.subscription_memberships;
  if (!subscriptions) return null;
  const validSubscriptions = subscriptions.filter(({ base_type, is_active }) => (base_type === 'membership' && is_active));
  return validSubscriptions.length ? validSubscriptions : null;
};

export const useDefaultUninsuredPrice = () => useSWR('default_uninsured_price', getDefaultUninsuredPrice, { revalidateOnFocus: false });
