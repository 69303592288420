import { Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { appUrl, ssoRedirectUrlCache } from 'src/js/utils';
import { getNextRoute } from 'src/js/utils/location';
import { IS_ACCOLADE_X, useAppVariant } from 'utils/hooks/useAppVariant';
import { stringify } from 'querystring';

function removeLastSlashFromUrl(url: string) {
  const match = url.match(/(.+)\/$/);
  if (match) {
    return match[1]; // return url without last /
  }
  return url;
}

function getPathFromUrl(url: string) {
  return url.split('?')[0];
}

export function urlPath(url: string) {
  return removeLastSlashFromUrl(getPathFromUrl(url));
}

export function redirectTo(url: string) {
  return { redirect: { destination: url, permanent: false } };
}

// type guard for comfortable work with providers which may return redirects (which use 'serverRedirectToLogin' inside)
export const isRedirectCase = (p: any): p is withRedirectToLoginProps => !!(p as withRedirectToLoginProps).redirect;

export type withTherapyRedirectProps = ReturnType<typeof redirectToTherapyAppsList>
export function redirectToTherapyAppsList() {
  return redirectTo(appUrl.booking.therapy.list);
}

export type withRedirectToLoginProps = ReturnType<typeof serverRedirectToLogin>
export function serverRedirectToLogin(url?: string) {
  return redirectTo(url ? `/login?next=${encodeURIComponent(url)}` : appUrl.login);
}

export const serverRedirectToMain = () => redirectTo('/');

export function serverRedirectToNext(query: Router['query']) {
  const nextRoute = getNextRoute(query);
  return redirectTo(nextRoute || (IS_ACCOLADE_X ? appUrl.accoladeX.dashboard : appUrl.dashboard));
}

export type withRedirectToDashboardProps = ReturnType<typeof serverRedirectToDashboard>
export function serverRedirectToDashboard() {
  return redirectTo(appUrl.dashboard);
}

export function useLoginPageRedirect(): boolean {
  const { isAccoladeX } = useAppVariant();
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  ssoRedirectUrlCache.clear();
  // Check if it's AccoladeX then redirect to AccoladeX login page
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV !== 'local' && isAccoladeX) {
      window.location.href = process.env.NEXT_PUBLIC_ACCOLADEX_URL;
    }
  });

  useEffect(() => {
    if (router.isReady) {
      const partner_id = (router.query.partner) ? router.query.partner.toString() : null;
      if (partner_id) {
        router.push(`${appUrl.authentication.ssoEntry}?${stringify(router.query)}`);
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return isLoading;
}
